import React, { useState, useEffect }from "react"
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'

import '../styles/TransitionStyles.css'

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
`

const Content = styled.div`
  color: #ffffff;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 50%;
`

const Name = styled.div`
  font-weight: 650;
  font-size: 30px;
  font-family: "SF Mono";
`

const Occupation = styled.div`
  opacity: 0.8;
  font-weight: 550;
  font-size: 30px;
  font-family: "SF Mono";
`

const Description = styled.div`
  font-family: "SF Mono"; 
  opacity: 0.7;
`

const Avatar = styled.div`
  width: 200px;
`

const UnorderedList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
`

const StyledLink = styled.a`
  color: white;
  opacity: 0.8;
`

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2f1d2b;
  font-family: "SF Display";
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const name = () => (
  <Name style={{ transitionDelay: '100ms' }}>Jake Chong</Name>
)

const occupation = () => (
  <Occupation style={{ transitionDelay: '200ms' }}> Software Engineer</Occupation>
)

const description = () => (
  <Description style={{ transitionDelay: '300ms' }}>
    I am a software engineer working at <StyledLink href="https://www.ridecell.com">Ridecell</StyledLink>, 
    a mobility platform startup in San Francisco.
  </Description>
)

const links = () => (
  <UnorderedList style={{ transitionDelay: '400ms' }}>
    <li>
      <StyledLink href="https://www.linkedin.com/in/jaekyungchong">
        <FontAwesomeIcon icon={faLinkedin} size="1x" />
      </StyledLink>
    </li>
    <li>
      <StyledLink href="https://github.com/jakechong">
        <FontAwesomeIcon icon={faGithub} size="1x" />
      </StyledLink>
    </li>
    <li>
      <StyledLink href="https://twitter.com/snowden">
        <FontAwesomeIcon icon={faTwitter} size="1x" />
      </StyledLink>
    </li>
  </UnorderedList>
)

const items = [name, occupation, description, links]

const IndexPage = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledContainer>
      <Box>
        <Content>
          <TransitionGroup component={null}>
            {isMounted && items.map((item, id) => (
              <CSSTransition key={id} classNames="fadeup" timeout={2000}>
                {item}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Content>
        {isMounted && 
          <CSSTransition key={5} classNames="fadeup" timeout={2000}>
            <Avatar>
              <img src="https://image.flaticon.com/icons/png/512/1803/1803671.png" />
            </Avatar>
          </CSSTransition>
        }
      </Box>
    </StyledContainer>
  )
}

export default IndexPage
